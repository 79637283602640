import Image from 'next/image';
import { getServerSession } from 'next-auth';

import { useAuthentication } from 'hooks';
import { getAuthOptions } from 'pages/api/auth/[...nextauth]';

import { INITIAL_AUTHORIZED_PATH } from 'utils/constants/menu';

import Dots from 'assets/icons/dots.svg';
import SemiCircleDots from 'assets/icons/semicircle-dots.svg';
import SemiTriangleDots from 'assets/icons/semitriangle-dots.svg';
import Square from 'assets/icons/square.svg';

import LoginForm from './LoginForm';

const Login = ({ featureFlags }) => {
  const { handleLogin } = useAuthentication({
    featureFlags,
  });

  return (
    <div className="w-screen h-screen flex flex-row ">
      <div className="h-full w-7/12 flex flex-col bg-login">
        <div className="mt-9 ml-12 mb-44">
          <Image
            src="/img/endless-logo.svg"
            width={170}
            height={44}
            alt="Endless logo"
          />
        </div>
        <Dots className="w-20 h-5 absolute left-24 top-40" />
        <Square className="w-7 h-7 absolute left-1/2 top-44" />
        <h1 className="ml-28 mb-6 text-7xl font-aeonik-medium">
          Simplify ops.
          Supercharge growth.
        </h1>
        <h3 className="ml-28 text-lg">
          A single dashboard to connect, manage, and scale your operations.
        </h3>
        <SemiCircleDots className="absolute left-1/2 bottom-52" />
        <Square className="w-7 h-7 absolute left-28 bottom-28" />
        <Dots className="w-20 h-5 absolute left-1/3 bottom-20" />
        <SemiTriangleDots className="absolute left-48 bottom-0" />
      </div>

      <div className="flex flex-col h-full w-5/12 z-10 bg-neutral-10 items-stretch justify-items-auto shadow-login">
        <div className="mx-20 mt-20 mb-16">
          <h2 className="text-3xl font-aeonik-medium">Welcome</h2>
          <h3 className="text-lg">Log in to Endless Commerce here.</h3>
        </div>
        <LoginForm onSubmit={handleLogin} />
      </div>
    </div>
  );
};

export async function getServerSideProps({ req, res }) {
  const session = await getServerSession(req, res, getAuthOptions(req, res));

  if (session) {
    return {
      redirect: {
        destination: INITIAL_AUTHORIZED_PATH,
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
}

export default Login;
